'use client'

// MUI Imports
import { useTheme } from '@mui/material/styles'

// Third-party Imports
import PerfectScrollbar from 'react-perfect-scrollbar'

// Component Imports
import { Menu, MenuItem, MenuSection, SubMenu } from '@menu/vertical-menu'
import { GenerateVerticalMenu } from '../../GenerateMenu'

// Hook Imports
import { useSettings } from '@core/hooks/useSettings'
import useVerticalNav from '@menu/hooks/useVerticalNav'

// Styled Component Imports
import StyledVerticalNavExpandIcon from '@menu/styles/vertical/StyledVerticalNavExpandIcon'

// Style Imports
import menuItemStyles from '@core/styles/vertical/menuItemStyles'
import menuSectionStyles from '@core/styles/vertical/menuSectionStyles'
import { useEffect, useState } from 'react'

import verticalMenuData from '../../../data/navigation/verticalMenuData'
import { SOCKET_CONNECT_TOKEN, UserLogout } from '@/views/common/commonUrl'
import { connect } from '@/utils/Socket'
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

const instance = Axios.create();
global.axios = setupCache(instance);

const NodeCache = require("node-cache");
global.myCache = new NodeCache();

const RenderExpandIcon = ({ open, transitionDuration }) => (
  <StyledVerticalNavExpandIcon open={open} transitionDuration={transitionDuration}>
    <i className='tabler-chevron-right' />
  </StyledVerticalNavExpandIcon>
)

const VerticalMenu = ({ scrollMenu, cookieData }) => {
  // Hooks
  const theme = useTheme()
  const verticalNavOptions = useVerticalNav()
  const { settings, updateSettings } = useSettings()
  const { isBreakpointReached } = useVerticalNav()

  // Vars
  const { transitionDuration } = verticalNavOptions
  const ScrollWrapper = isBreakpointReached ? 'div' : PerfectScrollbar

  const [menuData, setMenuData] = useState([]);
  useEffect(() => {
    if (settings.accesstoken == "") {
      UserLogout()
    } else {
      setMenuData(verticalMenuData(cookieData, settings.allowPages, settings.superadmin))
    }
  }, [settings.allowPages, settings.allowCards])

  useEffect(() => {
    connect(SOCKET_CONNECT_TOKEN)
  }, [])
  useEffect(() => {
    if (cookieData) {
      // settings.selectedUser.value = cookieData.type == 'Client' ? cookieData.username : null
      global.username = cookieData.dispname
      global.dispuserid = cookieData.username
      // global.myCache.set('usercode', cookieData.type == 'Admin' ? cookieData.username : null, 0)
    }
  }, [])

  return (
    // eslint-disable-next-line lines-around-comment
    /* Custom scrollbar instead of browser scroll, remove if you want browser scroll only */
    <ScrollWrapper
      {...(isBreakpointReached
        ? {
          className: 'bs-full overflow-y-auto overflow-x-hidden',
          onScroll: container => scrollMenu(container, false)
        }
        : {
          options: { wheelPropagation: false, suppressScrollX: true },
          onScrollY: container => scrollMenu(container, true)
        })}
    >
      {/* Incase you also want to scroll NavHeader to scroll with Vertical Menu, remove NavHeader from above and paste it below this comment */}
      {/* Vertical Menu */}
      <Menu
        popoutMenuOffset={{ mainAxis: 23 }}
        menuItemStyles={menuItemStyles(verticalNavOptions, theme, settings)}
        renderExpandIcon={({ open }) => <RenderExpandIcon open={open} transitionDuration={transitionDuration} />}
        renderExpandedMenuItemIcon={{ icon: <i className='tabler-circle text-xs' /> }}
        menuSectionStyles={menuSectionStyles(verticalNavOptions, theme)}
      >
        {menuData.map((item, index) => (
          item.disp && (item.children && item.children.length > 0 ? (
            <MenuSection key={index} label={item.label}>
              {item.children.map((child, index) => (
                child.disp && (<MenuItem key={index} href={child.href} icon={<i className={child.icon} />}>
                  {child.label}
                </MenuItem>)
              ))}
            </MenuSection>
          ) : (
            <MenuItem key={index} href={item.href} icon={<i className={item.icon} />}>
              {item.label}
            </MenuItem>
          ))
        ))}
      </Menu>
      {/* <Menu
        popoutMenuOffset={{ mainAxis: 23 }}
        menuItemStyles={menuItemStyles(verticalNavOptions, theme, settings)}
        renderExpandIcon={({ open }) => <RenderExpandIcon open={open} transitionDuration={transitionDuration} />}
        renderExpandedMenuItemIcon={{ icon: <i className='tabler-circle text-xs' /> }}
        menuSectionStyles={menuSectionStyles(verticalNavOptions, theme)}
      >
        <GenerateVerticalMenu menuData={facility} />
      </Menu> */}
    </ScrollWrapper>
  )
}

export default VerticalMenu
