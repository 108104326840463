import { Autocomplete, Button, Card, CardContent, Checkbox, Divider, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material"
import CustomAvatar from '@/@core/components/mui/Avatar'
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
    createColumnHelper,
    ExpandedState,
    getExpandedRowModel,
} from '@tanstack/react-table'
import styles from '@core/styles/table.module.css'
import { useSettings } from "@/@core/hooks/useSettings"
import { forwardRef, useEffect, useMemo, useRef, useState } from "react"
import classNames from "classnames"
import ChevronRight from "@/@menu/svg/ChevronRight"
import CustomTextField from "@/@core/components/mui/TextField"
import { APIURL, formatMoney, getCookie, UserLogout } from '@views/common/commonUrl'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment"
import format from "date-fns/format";
import ReactDatePicker from "react-datepicker";
import DatePickerWrapper from "@core/styles/libs/react-datepicker";
import _ from "lodash"

const columnHelper = createColumnHelper()

const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

const Filter = ({ column, table }) => {
    // Vars
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
    const columnFilterValue = column.getFilterValue()

    return (
        <CustomTextField
            fullWidth
            sx={{ minInlineSize: 100 }}
            value={columnFilterValue ?? ''}
            onChange={e => column.setFilterValue(e.target.value)}
            placeholder='Search...'
        />
    )
}

function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
}) {
    const ref = useRef(null)

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
}

const calculateSum = (data, accessor) => {
    return data ? data.reduce((total, row) => total + Number(row.getValue(accessor) || 0), 0) : '';
};

const SumFooter = ({ data, accessor }) => {
    const sum = calculateSum(data, accessor);
    return (
        <Typography sx={{ p: 3, fontWeight: '600' }}>
            {formatMoney(sum.toFixed(2))}
        </Typography>
    );
};

const sortStatusFn = (rowA, rowB, _columnId) => {
    const statusA = rowA.original.status
    const statusB = rowB.original.status
    const statusOrder = ['single', 'complicated', 'relationship']
    return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB)
}

const ClientWiseDetailReport = ({ handleClose }) => {
    const { settings, updateSettings } = useSettings()

    const [value, setValue] = useState();
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [dateRangeStart, setDateRangeStart] = useState(new Date(settings.billdate))
    const [dateRangeEnd, setDateRangeEnd] = useState(new Date(settings.billdate))

    const [columnFilters, setColumnFilters] = useState([])
    const [globalFilter, setGlobalFilter] = useState('')

    const [data, setData] = useState([])
    const [filterRows, setFilterRows] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [sumData, setSumData] = useState({
        all: 0.00,
        fo: 0.00,
        cm: 0.00,
        currency: 0.00,
        lpc: 0.00
    })
    const [type, setType] = useState('all')
    const [symbolGroup, setSymbolGroup] = useState(true)

    const [expanded, setExpanded] = useState({})
    const [selectedCode, setSelectedCode] = useState()

    const CustomInput = forwardRef((props, ref) => {
        const startDate = format(dateRangeStart, 'dd/MM/yyyy')
        const endDate = props.end !== null ? ` - ${format(dateRangeEnd, 'dd/MM/yyyy')}` : null
        const value = `${startDate}${endDate !== null ? endDate : ''}`

        return <TextField size='small' sx={{ mr: 3 }} inputRef={ref} label={props.label || ''} {...props} value={value} />
    })
    const handleOnChangeRange = dates => {
        setData([])
        setFilterRows([])
        setOriginalData([])
        setSumData({
            all: 0.00,
            fo: 0.00,
            cm: 0.00,
            currency: 0.00,
            lpc: 0.00
        })
        const [start, end] = dates
        setDateRangeStart(start)
        setDateRangeEnd(end)
    }


    // useEffect(() => {
    //     if (data) {
    //         fetchData()
    //     }
    // }, [])

    const fetchData = async () => {
        let data = JSON.stringify({
            "SessKey": settings.accesstoken,
            "ACode": selectedCode,
            "SDate": moment(dateRangeStart).format("YYYY-MM-DD"),
            "EDate": moment(dateRangeEnd).format("YYYY-MM-DD") //Monthly, Daily
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: APIURL + 'GetGrossDetailReportCustom',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await global.axios.request(config)
            .then(async (response) => {
                var result = response.data
                if (result.length > 0) {
                    if (result[0]['ApiStatus'] == true) {
                        if (result[0]['GrossReport'].length > 0) {
                            const finalArr = []
                            const unique = [...new Map(result[0]['GrossReport'].map(item =>
                                [item['scripname'], item])).values()];

                            for await (const ele of unique) {
                                var filterd = _.filter(result[0]['GrossReport'], function (o) {
                                    return o.scripname == ele.scripname;
                                })

                                var filterArr = []
                                if (filterd.length > 0) {
                                    for await (const fVal of filterd) {
                                        filterArr.push({
                                            segment: fVal.segment,
                                            scrip: fVal.scripname,
                                            symbol: fVal.symbol,
                                            openqty: fVal.openqty,
                                            prevclose: fVal.preclose,
                                            sellqty: fVal.sellqty,
                                            sellprice: parseFloat(fVal.sellprice).toFixed(2),
                                            sellvalue: parseInt(fVal.sellqty) * parseFloat(fVal.sellprice),
                                            bill: parseFloat(fVal.netpnl).toFixed(2),
                                            buyqty: fVal.buyqty,
                                            buyprice: parseFloat(fVal.buyprice).toFixed(2),
                                            buyvalue: parseInt(fVal.buyqty) * parseFloat(fVal.buyprice),
                                            netqty: fVal.NetQty,
                                            closeprice: parseFloat(fVal.closeprice).toFixed(2),
                                            billdisp: parseFloat(fVal.netpnl)
                                        })
                                    }
                                }
                                var obj = {
                                    segment: ele.segment,
                                    scrip: ele.Scrip == "" ? ele.symbol : ele.scripname,
                                    symbol: ele.symbol,
                                    openqty: ele.segment == 'CM' ? ele.openqty : '',
                                    prevclose: ele.segment == 'CM' ? ele.preclose : '',
                                    sellqty: ele.segment == 'CM' ? ele.sellqty : '',
                                    sellprice: ele.segment == 'CM' ? parseFloat(ele.sellprice).toFixed(2) : '',
                                    sellvalue: ele.segment == 'CM' ? parseInt(ele.sellqty) * parseFloat(ele.sellprice) : '',
                                    bill: ele.segment == 'CM' ? ele.netpnl : _.sumBy(filterArr, function (o) { return parseInt(o.bill); }).toFixed(2),
                                    buyqty: ele.segment == 'CM' ? ele.buyqty : '',
                                    buyprice: ele.segment == 'CM' ? parseFloat(ele.buyprice).toFixed(2) : '',
                                    buyvalue: ele.segment == 'CM' ? parseInt(ele.buyqty) * parseFloat(ele.buyprice) : '',
                                    netqty: ele.segment == 'CM' ? ele.netqty : '',
                                    closeprice: ele.segment == 'CM' ? parseFloat(ele.closeprice).toFixed(2) : '',
                                    billdisp: parseFloat(_.sumBy(filterArr, function (o) { return parseFloat(o.bill); }).toFixed(2)),
                                    subRows: filterArr,
                                }

                                // billdisp: ele.Segment == 'CM' ? parseFloat(ele.NetPnl) : parseFloat(_.sumBy(filterArr, function (o) { return parseFloat(o.bill); }).toFixed(2)),

                                finalArr.push(obj)
                            }

                            let sorted_array = finalArr

                            setData(sorted_array)
                            setFilterRows(sorted_array)
                            setOriginalData(result[0]['GrossReport'])

                            setSumData({
                                all: _.sumBy(result[0]['GrossReport'], function (o) { return parseInt(o.netpnl); }),
                                fo: _.sumBy(_.filter(result[0]['GrossReport'], { segment: 'FO' }), function (o) { return parseInt(o.netpnl); }),
                                cm: _.sumBy(_.filter(result[0]['GrossReport'], { segment: 'CM' }), function (o) { return parseInt(o.netpnl); }),
                                currency: _.sumBy(_.filter(result[0]['GrossReport'], { segment: 'CD' }), function (o) { return parseInt(o.netpnl); }),
                                lpc: _.sumBy(_.filter(result[0]['GrossReport'], { scripname: 'LPChng' }), function (o) { return parseInt(o.netpnl); }),
                            })
                        }
                    } else if (result[0]['ApiStatus'] == false && result[0]['Remark'] == "Session Expired, Re-Login") {
                        toast.error(result[0]['Remark'], {
                            position: 'bottom-right'
                        })
                        updateSettings({ selectedUser: {} })
                        UserLogout()
                    } else {
                        toast.error('Something went wrong!', {
                            position: 'bottom-right'
                        })
                    }
                } else {
                    toast.error('No Data Found!', {
                        position: 'bottom-right'
                    })
                }
            })
            .catch((error) => {
                toast.error('Internal Server Error!', {
                    position: 'bottom-right'
                })
            });
    }


    const columns = useMemo(
        () => [
            columnHelper.accessor('scrip', {
                cell: ({ row, getValue }) => (
                    <div>
                        {row.getCanExpand() ? (
                            <Button
                                {...{
                                    onClick: row.getToggleExpandedHandler(),
                                    style: { cursor: 'pointer', background: 'transparent', padding: 0, minWidth: 0 },
                                }}
                            >
                                {row.getIsExpanded() ? <i className="tabler-minus" /> : <i className="tabler-plus" />}
                            </Button>
                        ) : (
                            ''
                        )}
                        {getValue()}
                    </div>
                ),
                header: 'Scrip',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('billdisp', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right'
                    color={info.row.original.bill > 0 ? 'primary' : info.row.original.bill < 0 ? 'error' : ''}
                >
                    {parseFloat(info.row.original.bill).toFixed(2)}
                </Typography>,
                header: 'Total',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('openqty', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'Open Qty',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('prevclose', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'Prev. ClosePrice',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('sellqty', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'Sell Qty',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('sellprice', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'Sell Price',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('sellvalue', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'Sell Value',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('billdisp', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right'
                    color={info.row.original.bill > 0 ? 'primary' : info.row.original.bill < 0 ? 'error' : ''}
                >
                    {parseFloat(info.row.original.bill).toFixed(2)}
                </Typography>,
                header: 'PNL',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('buyqty', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'Buy Qty',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('buyprice', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'Buy Price',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('buyvalue', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'Buy Value',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('netqty', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'NetQty',
                footer: info => info.column.id,
            }),
            columnHelper.accessor('closeprice', {
                cell: info => <Typography align='right' alignContent='right' justifyContent='right' >{info.getValue()}</Typography>,
                header: 'Close Price',
                footer: info => info.column.id,
            }),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data.length]
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter
        },
        state: {
            columnFilters,
            globalFilter,
            expanded
        },
        initialState: {
            // pagination: {
            //     pageSize: 5,
            // },
            columnVisibility: {
                buyvalue: false,
                sellvalue: false
            }
        },
        onExpandedChange: setExpanded,
        getSubRows: row => row.subRows,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getExpandedRowModel: getExpandedRowModel(),
    })

    // useEffect(() => {
    //     if (table.getState().columnFilters[0]?.id == 'billdisp') {
    //         if (table.getState().sorting[0]?.id != 'billdisp') {
    //             table.setSorting([{ id: 'billdisp', desc: false }])
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [table.getState().columnFilters[0]?.id])

    const handleFilter = (type) => {
        setType(type)
        if (type == 'all') {
            setData(filterRows)
            setSymbolGroup(true)
        } else if (type == 'fo') {
            var aData = _.filter(originalData, function (item) {
                return (item.segment == 'FO')
            })
            var finalArr = []
            aData.forEach(ele => {
                var obj = {
                    segment: ele.segment,
                    scrip: ele.scripname == 'Other Charges' || ele.scripname == 'LPChng' || ele.scripname == 'STT' ? ele.scripname : ele.symbol,
                    symbol: ele.scripname,
                    openqty: ele.openqty,
                    prevclose: ele.preclose,
                    sellqty: ele.sellqty,
                    sellprice: ele.sellprice,
                    sellvalue: parseInt(ele.sellqty) * parseFloat(ele.sellprice),
                    bill: ele.netpnl,
                    buyqty: ele.buyqty,
                    buyprice: ele.buyprice,
                    buyvalue: parseInt(ele.buyqty) * parseFloat(ele.buyprice),
                    netqty: ele.netqty,
                    closeprice: ele.closeprice,
                    billdisp: ele.netpnl,
                }
                finalArr.push(obj)
            });
            setData(finalArr)

        } else if (type == 'cm') {
            var aData = _.filter(originalData, function (item) {
                return (item.segment == 'CM')
            })
            var finalArr = []
            aData.forEach(ele => {
                var obj = {
                    segment: ele.segment,
                    scrip: ele.scripname,
                    symbol: ele.symbol,
                    openqty: ele.openqty,
                    prevclose: ele.preclose,
                    sellqty: ele.sellqty,
                    sellprice: ele.sellprice,
                    sellvalue: parseInt(ele.sellqty) * parseFloat(ele.sellprice),
                    bill: parseFloat(ele.netpnl),
                    buyqty: ele.buyqty,
                    buyprice: ele.buyprice,
                    buyvalue: parseInt(ele.buyqty) * parseFloat(ele.buyprice),
                    netqty: ele.netqty,
                    closeprice: ele.closeprice,
                    billdisp: ele.netpnl,
                }
                finalArr.push(obj)
            });
            setData(finalArr)
        } else if (type == 'cd') {
            var aData = _.filter(originalData, function (item) {
                return (item.Segment == 'CD')
            })
            setData(aData)
        }
    }

    const handleSymbolGroupFilter = (chk) => {
        if (type == 'all') {
            setSymbolGroup(chk)
            if (chk) {
                setData(filterRows)
            } else {
                var finalArr = []

                originalData.forEach(ele => {
                    var obj = {
                        segment: ele.segment,
                        scrip: ele.symbol == null ? ele.scripname : ele.symbol,
                        symbol: ele.symbol,
                        openqty: ele.openqty,
                        prevclose: ele.preclose,
                        sellqty: ele.sellqty,
                        sellprice: ele.sellprice,
                        sellvalue: parseInt(ele.sellqty) * parseFloat(ele.sellprice),
                        bill: parseFloat(ele.netpnl),
                        buyqty: ele.buyqty,
                        buyprice: ele.buyprice,
                        buyvalue: parseInt(ele.buyqty) * parseFloat(ele.buyprice),
                        netqty: ele.netqty,
                        closeprice: ele.closeprice,
                        billdisp: parseFloat(ele.netpnl),
                    }
                    finalArr.push(obj)
                });
                setData(finalArr)
            }
        }
    }

    useEffect(() => {
        const fetchOptions = async () => {
            setData([])
            setFilterRows([])
            setOriginalData([])
            setSumData({
                all: 0.00,
                fo: 0.00,
                cm: 0.00,
                currency: 0.00,
                lpc: 0.00
            })
            if (inputValue === '') {
                setOptions(value ? [value] : [{
                    id: '',
                    value: '',
                    label: ''
                }]);
            }
            else {
                var data = JSON.stringify({
                    "SessKey": settings.accesstoken,
                    "SearchKey": inputValue,
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: APIURL + 'GetActiveClient',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                global.axios.request(config)
                    .then(async function (response) {
                        var result = response.data
                        if (result.length > 0) {
                            if (result[0]['ApiStatus'] == true) {
                                if (result[0]['ActiveClient'].length > 0) {
                                    const finalArr = []
                                    result[0]['ActiveClient'].forEach(ele => {
                                        finalArr.push({
                                            id: ele['ACode'],
                                            label: ele['ACode'] + " - " + ele['Name'],
                                            value: ele['ACode']
                                        })
                                    })
                                    setOptions(finalArr)
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
        if (settings && settings.accesstoken && settings.accesstoken !== "") {
            fetchOptions()
        }
    }, [inputValue])

    const handleClientSearchVal = (newval) => {
        if (newval) {
            setValue(newval.label)
            setSelectedCode(newval.value)
        } else {
            setValue('')
            setSelectedCode('')
        }
    }

    return (
        <>
            <ToastContainer />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Typography variant='h5'>
                        Gross P&L Report
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.label
                        }
                        isOptionEqualToValue={(option, value) => option.label === value}
                        filterOptions={(x) => x}
                        options={options}
                        autoComplete
                        autoHighlight={true}
                        filterSelectedOptions
                        value={value}
                        onChange={(event, newValue) => {
                            handleClientSearchVal(newValue)
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Client" />}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <DatePickerWrapper>
                        <ReactDatePicker
                            sx={{ width: '200px' }}
                            selectsRange
                            monthsShown={2}
                            endDate={dateRangeEnd}
                            selected={dateRangeStart}
                            startDate={dateRangeStart}
                            shouldCloseOnSelect={false}
                            id='date-range-picker-months'
                            onChange={handleOnChangeRange}
                            customInput={<CustomInput end={dateRangeEnd} start={dateRangeStart} fullWidth />}
                        />
                    </DatePickerWrapper>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button variant="contained" onClick={() => fetchData()}>Submit</Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button onClick={() => handleClose()} sx={{ float: 'right' }}>
                        <i className='tabler-x' />
                    </Button>
                </Grid>
            </Grid>

            <Stack direction='row' spacing={5} sx={{ mt: 3 }}>
                <div key={1} className='flex items-center gap-4' style={{ cursor: 'pointer' }} onClick={() => handleFilter('all')}>
                    <CustomAvatar skin='light' color={type == 'all' ? 'success' : ''} variant='rounded' size={34}>
                        <i className='tabler-report' />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <div className='flex flex-col'>
                            <Typography className='font-medium' color='text.primary'>
                                All
                            </Typography>
                            <Typography variant='body2' color={sumData.all > 0 ? 'primary' : 'error'}>{formatMoney(sumData.all)}</Typography>
                        </div>
                    </div>
                </div>
                <div key={2} className='flex items-center gap-4' style={{ cursor: 'pointer' }} onClick={() => handleFilter('fo')}>
                    <CustomAvatar skin='light' color={type == 'fo' ? 'success' : ''} variant='rounded' size={34}>
                        <i className='tabler-report' />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <div className='flex flex-col'>
                            <Typography className='font-medium' color='text.primary'>
                                NSE Derivatives
                            </Typography>
                            <Typography variant='body2' color={sumData.fo > 0 ? 'primary' : 'error'}>{formatMoney(sumData.fo)}</Typography>
                        </div>
                    </div>
                </div>
                <div key={3} className='flex items-center gap-4' style={{ cursor: 'pointer' }} onClick={() => handleFilter('cm')}>
                    <CustomAvatar skin='light' color={type == 'cm' ? 'success' : ''} variant='rounded' size={34}>
                        <i className='tabler-report' />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <div className='flex flex-col'>
                            <Typography className='font-medium' color='text.primary'>
                                BSE Equity
                            </Typography>
                            <Typography variant='body2' color={sumData.cm > 0 ? 'primary' : 'error'}>{formatMoney(sumData.cm)}</Typography>
                        </div>
                    </div>
                </div>
                <div key={4} className='flex items-center gap-4' style={{ cursor: 'pointer' }}>
                    <CustomAvatar skin='light' variant='rounded' size={34}>
                        <i className='tabler-report' />
                    </CustomAvatar>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <div className='flex flex-col'>
                            <Typography className='font-medium' color='text.primary'>
                                LPC
                            </Typography>
                            <Typography variant='body2' color={sumData.lpc > 0 ? 'primary' : sumData.lpc < 0 ? 'error' : ''}>{formatMoney(sumData.lpc)}</Typography>
                        </div>
                    </div>
                </div>
                <Divider orientation="vertical" />
                {type == 'all' && <div key={5} className='flex items-center gap-4' style={{ cursor: 'pointer' }}>
                    <div className='flex flex-wrap justify-between items-center gap-x-4 gap-y-1 is-full'>
                        <div className='flex flex-col'>
                            <FormControlLabel control={<Checkbox checked={symbolGroup} onClick={(e) => handleSymbolGroupFilter(e.target.checked)} />} label="Symbol Wise Grouping" />
                        </div>
                    </div>
                </div>}
            </Stack>
            <Grid container spacing={1} sx={{ mt: 5 }} key={2}>
                <Grid item xs={12}>
                    <div className={settings.mode == 'light' ? 'overflow-x-auto tableFixHead light scroller' : 'overflow-x-auto tableFixHead dark scrollerdark'} style={{ height: '80vh' }} >
                        <table className={styles.table}>
                            <thead>
                                {table.getHeaderGroups().map((headerGroup, index) => (
                                    <tr key={index}>
                                        {headerGroup.headers.map((header, index) => {
                                            return (
                                                <th key={index}>
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={classNames({
                                                                    'flex items-center': header.column.getIsSorted(),
                                                                    'cursor-pointer select-none': header.column.getCanSort()
                                                                })}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                                {{
                                                                    asc: <ChevronRight fontSize='1.25rem' className='-rotate-90' />,
                                                                    desc: <ChevronRight fontSize='1.25rem' className='rotate-90' />
                                                                }[header.column.getIsSorted()] ?? null}
                                                            </div>
                                                            {header.column.getCanFilter() && <Filter column={header.column} table={table} />}
                                                        </>
                                                    )}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            {table.getFilteredRowModel().rows.length === 0 ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={table.getVisibleFlatColumns().length} className='text-center'>
                                            No data available
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {table.getRowModel().rows.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                {row.getVisibleCells().map((cell, index) => {
                                                    return <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            )}
                            <tfoot>
                                <tr>
                                    <th align='left' style={{ paddingLeft: '5px' }}>{table.getRowModel().rows.length > 0 ? table.getRowModel().rows.length + ' records' : ''}</th>
                                    <th align="right"><SumFooter data={table.getFilteredRowModel().rows} accessor={"billdisp"} /></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th align="right"><SumFooter data={table.getFilteredRowModel().rows} accessor={"buyvalue"} /></th>
                                    <th align="right"><SumFooter data={table.getFilteredRowModel().rows} accessor={"billdisp"} /></th>
                                    <th></th>
                                    <th align="right"><SumFooter data={table.getFilteredRowModel().rows} accessor={"sellvalue"} /></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                </Grid>
            </Grid>

        </>
    )
}

export default ClientWiseDetailReport
