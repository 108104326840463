'use client'

// Third-party Imports
import classnames from 'classnames'

// Hook Imports
import { useSettings } from '@core/hooks/useSettings'

// Util Imports
import { verticalLayoutClasses } from '@layouts/utils/layoutClasses'

// Styled Component Imports
import StyledMain from '@layouts/styles/shared/StyledMain'
import { useEffect } from 'react'
import { useRouter } from 'next/navigation'


const LayoutContent = ({ children }) => {
	// Hooks
	const { settings } = useSettings()
	const router = useRouter()
	// Vars
	const contentCompact = settings.contentWidth === 'compact'
	const contentWide = settings.contentWidth === 'wide'




	return (
		<StyledMain
			isContentCompact={contentCompact}
			className={classnames(verticalLayoutClasses.content, 'flex-auto', {
				[`${verticalLayoutClasses.contentCompact} is-full`]: contentCompact,
				[verticalLayoutClasses.contentWide]: contentWide
			})}
			sx={{ pb: 0 }}
		>
			{children}
		</StyledMain>
	)
}

export default LayoutContent
