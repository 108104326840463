'use client'

// Third-party Imports
import classnames from 'classnames'

// Component Imports
import NavToggle from './NavToggle'
import ModeDropdown from '@components/layout/shared/ModeDropdown'
import UserDropdown from '@components/layout/shared/UserDropdown'
import ClientHeader from '@views/common/ClientHeader'
import DashboardLink from '@views/common/DashboardLink'

// Util Imports
import { verticalLayoutClasses } from '@layouts/utils/layoutClasses'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography } from '@mui/material'
import { usePathname } from 'next/navigation'
import { forwardRef, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { UserLogout } from '@/views/common/commonUrl'
import _ from 'lodash'
import { useSettings } from '@/@core/hooks/useSettings'
import ClientWiseDetailReport from '@/views/gross-pnl-report/ClientWiseDetailReport'

const NavbarContent = ({ cookieData }) => {
	const { settings } = useSettings()
	const pathname = usePathname()
	const [title, setTitle] = useState()


	useEffect(() => {
		if (pathname) {
			if (pathname == '/client-privileges') {
				setTitle('Client Privileges')
			} else if (pathname == '/position-analytics') {
				setTitle('Position Analytics')
			} else if (pathname == '/position') {
				setTitle('Position')
			} else if (pathname == '/holding') {
				setTitle('Holding')
			} else if (pathname == '/ctcl-master') {
				setTitle('CTCL Master')
			} else if (pathname == '/ctcl-list') {
				setTitle('CTCL List')
			} else if (pathname == '/client-dashboard') {
				setTitle('Dashboard')
			} else if (pathname == '/bank-book') {
				setTitle('Bank Pass Book')
			} else if (pathname == '/payment-book') {
				setTitle('Payment Book')
			} else if (pathname == '/position-mis') {
				setTitle('Position MIS')
			} else if (pathname == '/client-statement') {
				setTitle('Statement')
			} else if (pathname == '/client-balance') {
				setTitle('Balance')
			} else if (pathname == '/margin') {
				setTitle('Margin')
			} else if (pathname == '/lpchange') {
				setTitle('LPChg')
			} else if (pathname == '/tradebook') {
				setTitle('Trade Book')
			} else if (pathname == '/pnl-report') {
				setTitle('P&L Reports')
			} else if (pathname == '/gross-pnl-report') {
				setTitle('Gross P&L Report')
			} else if (pathname == '/backoffice') {
				setTitle('Back Office')
			} else if (pathname == '/cr') {
				setTitle('CR')
			} else if (pathname == '/edit-interest') {
				setTitle('Edit Interest')
			} else if (pathname == '/admin-manager') {
				setTitle('Admin Manager')
			} else if (pathname == '/suadabatch-details') {
				setTitle('Suada Batch Details')
			} else if (pathname == '/transaction') {
				setTitle('Transaction')
			} else if (pathname == '/register') {
				setTitle('Register')
			} else if (pathname == '/passbook') {
				setTitle('Passbook')
			} else if (pathname == '/user-bank') {
				setTitle('User Bank')
			} else if (pathname == '/jvbook') {
				setTitle('JV Book')
			} else if (pathname == '/pnl-analytics') {
				setTitle('P&L Analytics')
			} else {
				setTitle('Dashboard')
			}
		}
	}, [pathname])

	const [state, setState] = useState('Active')
	const [count, setCount] = useState(0)
	const [remaining, setRemaining] = useState(0)

	const onIdle = () => {
		setState('Idle')
		document.cookie = 'KalpUser=; Max-Age=-99999999;';
		window.location = "/login";
	}

	const onActive = () => {
		setState('Active')
	}

	const onAction = () => {
		setCount(count + 1)
	}

	const { getRemainingTime } = useIdleTimer({
		onIdle,
		onActive,
		onAction,
		timeout: 900_000, //900_000
		throttle: 500
	})

	useEffect(() => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000))
		}, 500)

		return () => {
			clearInterval(interval)
		}
	})

	const [open, setOpen] = useState(false)
	const handleClose = () => setOpen(false)
	const handleDetails = async () => {
		setOpen(true)
	}


	return (
		<>
			<div className={classnames(verticalLayoutClasses.navbarContent, 'flex items-center justify-between gap-4 is-full')} style={{ paddingBottom: 0 }}>
				<div className='flex items-center gap-4'>
					<NavToggle />
					<Typography variant='h5'>{title}</Typography>
				</div>
				<div className='flex items-center'>
					{cookieData.type == 'Admin' && <DashboardLink />}

					<Button color='secondary' startIcon={<i className='tabler-browser' />} href="https://www.kalpvruks.com/home/login" target='_blank'>
						Old Panel
					</Button>

					{cookieData.type == 'Admin' && <ClientHeader />}

					{/* LIQUID ALGO PANEL */}
					{settings.superadmin && <IconButton component={Link} href="http://14.195.72.131:13002/#/admin-login" target='_blank' className='text-textPrimary'>
						<i className='tabler-clock-play' />
					</IconButton>}

					{/* Gross PnL Monthly PANEL */}
					{settings.superadmin && <IconButton className='text-textPrimary' onClick={() => handleDetails()}>
						<i className='tabler-currency-rupee' />
					</IconButton>}

					{/* CR PANEL */}
					{settings.superadmin && <IconButton component={Link} href="/cr" className='text-textPrimary'>
						<i className='tabler-cpu' />
					</IconButton>}

					{/* BACKOFFICE PANEL */}
					{settings.superadmin && <IconButton component={Link} href="/backoffice" className='text-textPrimary'>
						<i className='tabler-device-desktop-analytics' />
					</IconButton>}


					<ModeDropdown />
					<UserDropdown />
				</div>
			</div>

			<Dialog
				fullScreen
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}
				sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
			>
				<DialogContent sx={{ p: 3 }}>
					<ClientWiseDetailReport handleClose={handleClose} />
				</DialogContent>
			</Dialog>
		</>
	)
}

export default NavbarContent
