
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { APIURL, getCookie } from '@views/common/commonUrl'
import { useSettings } from '@/@core/hooks/useSettings';
import _ from 'lodash';

const ClientHeader = () => {
    const { settings, updateSettings } = useSettings()
    const [value, setValue] = useState(settings.selectedUser && {
        id: settings.selectedUser.id,
        value: settings.selectedUser.value,
        label: settings.selectedUser.label,
    });
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([{
        id: '',
        value: '',
        label: ''
    }]);

    useEffect(() => {
        const fetchOptions = async () => {
            if (inputValue === '') {
                setOptions(value ? [value] : [{
                    id: '',
                    value: '',
                    label: ''
                }]);
            }
            else {
                var data = JSON.stringify({
                    "SessKey": settings.accesstoken,
                    "SearchKey": inputValue,
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: APIURL + 'GetActiveClient',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                global.axios.request(config)
                    .then(async function (response) {
                        var result = response.data
                        if (result.length > 0) {
                            if (result[0]['ApiStatus'] == true) {
                                if (result[0]['ActiveClient'].length > 0) {
                                    const finalArr = []
                                    result[0]['ActiveClient'].forEach(ele => {
                                        finalArr.push({
                                            id: ele['ACode'],
                                            label: ele['ACode'] + " - " + ele['Name'],
                                            value: ele['ACode']
                                        })
                                    })
                                    setOptions(finalArr)
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
        if (settings && settings.accesstoken && settings.accesstoken !== "") {
            fetchOptions()
        }
    }, [inputValue])


    const handleClientSearchVal = (newval) => {
        if (newval && !_.isNil(newval)) {
            setValue(newval)
            updateSettings({ selectedUser: newval })
        } else {
            setValue({
                id: '',
                label: '',
                value: ''
            })
            updateSettings({ selectedUser: {} })
        }
    }
    return (
        <Autocomplete
            disablePortal
            size='small'
            id="combo-box-demo"
            // getOptionLabel={(option) =>
            //     typeof option === 'string' ? option : option.label
            // }
            getOptionLabel={(option) => option.label || ""}
            // getOptionSelected={(option, value) => option.id === value.id}
            // getOptionSelected={(option, value) =>
            //     value === undefined || value === "" || option.id === value.id
            // }
            isOptionEqualToValue={(option, value) =>
                value === undefined || value === "" || option.id === value.id
            }
            // isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={(x) => x}
            options={options.length > 0 && options}
            autoComplete
            filterSelectedOptions
            value={value.id && value}
            onChange={(event, newValue) => {
                handleClientSearchVal(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            sx={{ width: 250 }}
            renderInput={(params) => <TextField {...params} label="Client" />}
        />
    )
}

export default ClientHeader
