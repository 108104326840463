const verticalMenuData = (cdata, setting, superadmin) => [
	{
		label: 'Dashboard',
		href: '/home',
		icon: 'tabler-smart-home',
		disp: cdata.type == 'Admin' && true,
		children: []
	},
	{
		label: 'Dashboard',
		href: '/client-dashboard',
		icon: 'tabler-smart-home',
		disp: cdata.type == 'Client' && true,
		children: []
	},
	{
		label: 'Client Request',
		href: '/about',
		icon: 'tabler-git-pull-request',
		disp: false,
		children: []
	},
	{
		label: 'Portfolio',
		icon: 'tabler-file-invoice',
		disp: true,
		children: [
			{
				label: 'Position',
				icon: 'tabler-terminal',
				disp: setting.position,
				href: '/position'
			},
			{
				label: 'Holding',
				icon: 'tabler-chart-pie',
				disp: setting.holding,
				href: '/holding'
			},
			{
				label: 'Position MIS',
				icon: 'tabler-terminal',
				disp: setting.positionmis,
				href: '/position-mis'
			},
			{
				label: 'Position Analytics',
				icon: 'tabler-external-link',
				disp: setting.positionanalytics,
				href: '/position-analytics'
			},
		]
	},
	{
		label: 'Reports',
		icon: 'tabler-file-invoice',
		disp: true,
		children: [
			{
				label: 'Client Statement',
				icon: 'tabler-layers-subtract',
				disp: setting.ledger,
				href: '/client-statement'
			},
			{
				label: 'Client Balance',
				icon: 'tabler-brand-slack',
				disp: setting.balancebook,
				href: '/client-balance'
			},
			{
				label: 'Margin',
				icon: 'tabler-chart-bar',
				disp: setting.margin,
				href: '/margin'
			},
			{
				label: 'LPChg',
				icon: 'tabler-briefcase',
				disp: setting.lpc,
				href: '/lpchange'
			},
			{
				label: 'Tradebook',
				icon: 'tabler-printer',
				disp: setting.tradebook,
				href: '/tradebook'
			},
			{
				label: 'P&L Report',
				icon: 'tabler-activity',
				disp: setting.pnl,
				href: '/pnl-report'
			},
			{
				label: 'Gross P&L Report',
				icon: 'tabler-book',
				disp: setting.grossreport,
				href: '/gross-pnl-report'
			},
			{
				label: 'P&L Analytics',
				icon: 'tabler-percentage',
				disp: setting.pnlalytics,
				href: '/pnl-analytics'
			},
		]
	},
	{
		label: 'Back Office',
		icon: 'tabler-file-invoice',
		disp: true,
		children: [
			{
				label: 'Transaction',
				icon: 'tabler-transaction-rupee',
				disp: setting.paymentbook,
				href: '/transaction'
			},
			{
				label: 'Register',
				icon: 'tabler-registered',
				disp: setting.bankbook,
				href: '/register'
			},
			{
				label: 'Pass Book',
				icon: 'tabler-book',
				disp: setting.bankbook,
				href: '/passbook'
			},
			{
				label: 'User Bank',
				icon: 'tabler-building-bank',
				disp: setting.bankbook,
				href: '/user-bank'
			},
			{
				label: 'JV Book',
				icon: 'tabler-layers-subtract',
				disp: setting.jvbook,
				href: '/jvbook'
			},
			// {
			// 	label: 'Payment Book',
			// 	icon: 'tabler-layers-subtract',
			// 	disp: cdata.type == 'Admin' && true,
			// 	href: '/payment-book'
			// },
			// {
			// 	label: 'Bank Passbook',
			// 	icon: 'tabler-git-pull-request',
			// 	disp: cdata.type == 'Admin' && true,
			// 	href: '/bank-book'
			// },
			// {
			// 	label: 'JV Book',
			// 	icon: 'tabler-git-pull-request',
			// 	disp: cdata.type == 'Admin' && true,
			// 	href: '/forms/form-validation'
			// },
		]
	},
]

export default verticalMenuData
