import { useSettings } from "@/@core/hooks/useSettings";
import { Button, Link, Menu, MenuItem } from "@mui/material"
import { redirect } from "next/navigation";
import { useState } from "react";

const DashboardLink = () => {
    const { settings } = useSettings()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [ctcl, setctcl] = useState(null);
    const ctclopen = Boolean(ctcl);
    const handleCtclClick = (event) => {
        setctcl(event.currentTarget);
    };
    const handleCtclClose = () => {
        setctcl(null);
    };

    return (
        <>
            {/* <Button color='secondary' startIcon={<i className='tabler-credit-card' />}>
                Fix Value
            </Button> */}
            {/* {settings.allowPages && settings.allowPages.ctclbook && <Button color='secondary' startIcon={<i className='tabler-users' />} href="/ctcl-list">
                CTCL Book
            </Button>} */}
            {settings.allowPages && settings.allowPages.ctclbook && <Button color='secondary' startIcon={<i className='tabler-users' />} onClick={handleCtclClick}>
                CTCL Book
            </Button>}
            {settings.allowPages && settings.allowPages.ctclbook && <Menu
                id="basic-menu"
                anchorEl={ctcl}
                open={ctclopen}
                onClose={handleCtclClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem>
                    <Link href="/ctcl-master" underline="none" color='info'>Master</Link>
                </MenuItem>
                <MenuItem>
                    <Link href="/ctcl-list" underline="none" color='info'>List</Link>
                </MenuItem>
            </Menu>}
            {/* <Button color='secondary' startIcon={<i className='tabler-chart-bar' />}>
                Short Sells
            </Button> */}
            {/* <Button color='secondary' startIcon={<i className='tabler-book' />}>
                Odd Scrip Trade
            </Button>
            <Button color='secondary' startIcon={<i className='tabler-user-x' />}>
                Inactive Client Trade
            </Button> */}
            {/* <Button color='secondary' startIcon={<i className='tabler-external-link' />} component={Link} href="/position-analytics">
                Others
            </Button> */}
            {/* <Button color='secondary' startIcon={<i className='tabler-users' />}>
                Users Profile
            </Button> */}
            {settings.allowPages && settings.allowPages.users && <><Button color='secondary' startIcon={<i className='tabler-users' />} onClick={handleClick}>
                Users
            </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <Link href="/client-privileges" underline="none" color='info'>
                        <MenuItem>
                            Client
                        </MenuItem>
                    </Link>
                    <Link href="/admin-manager" underline="none" color='info'>
                        <MenuItem>
                            Admin Manager
                        </MenuItem>
                    </Link>
                    <Link href="/edit-interest" underline="none" color='info'>
                        <MenuItem>
                            Edit Interest
                        </MenuItem>
                    </Link>
                    <Link href="/logs" underline="none" color='info'>
                        <MenuItem>
                            Logs
                        </MenuItem>
                    </Link>
                </Menu></>}
        </>
    )
}

export default DashboardLink
